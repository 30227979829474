import Vue from 'vue';

export const data = Vue.observable({
  closed: true,
  title: null,
  text: null,
  ok: null,
});

export const open = (title, text, okLabel) => {
  data.title = title;
  data.text = text;
  data.ok = okLabel;
  data.closed = false;
};

export const close = () => {
  data.closed = true;
  data.title = null;
  data.text = null;
  data.ok = null;
};

export default {
  data,

  open,
  close,
};
