<template>
  <AlertModalContainer>
    <template #default="{ opened, title, text, okLabel, close }">
      <BModal
        v-if="opened"
        visible
        centered
        modal-class="alert-box"
        @hidden="close"
        @close="close"
        @ok="close"
      >
        <template #modal-title>{{title }}</template>

        <template #modal-footer>
          <button @click.prevent="close" class="button-accept btn btn-primary">
            {{ okLabel }}
          </button>
        </template>

        {{ text }}
      </BModal>
    </template>
  </AlertModalContainer>
</template>

<script>

import AlertModalContainer from './AlertModalContainer.vue';
import { BModal } from 'bootstrap-vue';

export default {
  components: {
    BModal,
    AlertModalContainer,
  },
};

</script>
