<template>
  <div>
    <slot :opened="opened" :title="title" :text="text" :okLabel="okLabel" :close="close"></slot>
  </div>
</template>

<script>

import alertService from '../services/alertService.js';

export default {
  computed: {
    opened () {
      return !alertService.data.closed;
    },
    title () {
      return alertService.data.title;
    },
    text () {
      return alertService.data.text;
    },
    okLabel () {
      return alertService.data.ok;
    },
  },
  methods: {
    close () {
      alertService.close();
    },
  },
};

</script>

<style lang="scss"></style>
